import { Model } from "./Model";
import { UserDetails } from "./UserDetails";

export class User extends Model {

  constructor(id: number, attributes: any) {
    super(id);

    this.id = attributes.id ?? 0;
    this.id_accounts = attributes.id_accounts;
    this.id_role = attributes.id_role;
    this.login = attributes.login;
    this.email = attributes.email;
    this.name = attributes.name;
    this.seller_code = attributes.seller_code;
    this.created = attributes.created;
    this.modified = attributes.modified;
    this.last_login = attributes.last_login;

    this.account = attributes.account_detail ? attributes.account_detail.account : '';

    this.role_enum = attributes.role_enum;

    if (attributes.detail) {
      this.detail = new UserDetails(attributes.detail);
    }
    else {
      this.detail = new UserDetails({});
    }
  }

  id_accounts: number;
  id_role: number;
  login: string;
  email: string;
  name: string;
  seller_code: number;
  created: Date;
  modified: Date;
  last_login: Date;
  account: string;
  role_enum: string;
  detail: UserDetails;

}
