import { User } from '@/code/models/User';
import { BaseService } from '@/code/services/base/baseService';
import axios2 from '@/axios2';
import localStorageUtil from '@/code/util/localStorageUtil';
import router from '@/router';
import { EUserRole } from '@/code/enums/EUserRole';
import Swal from 'sweetalert2';

export class UserService extends BaseService<User> {
  constructor() {
    super(User);
  }

  getCurrentUser() : Promise<Boolean> {
    const that = this;
    return new Promise((resolve, reject) => {
      axios2.get(`users/me?account_detail=1`)
      .then(
        (response: any) => {
          let result = new User(0, {});
          if (response.data && response.data.data) {
            //result = new User(parseInt(response.data.data.id), response.data.data);
            
            let roles = Object.values(EUserRole);
            let isValidRole = roles.filter((r) => { return r === response.data.data.role_enum}).length > 0;
            if (!isValidRole) {
              Swal.fire('A permissão do usuário é inválida ou não permite realizar login no painel');
              router.push({ name: 'Login' });
            }
            localStorageUtil.saveCurrentUser(response.data.data);
          }
          resolve(true);
        }
      ),
      (error: any) => {
        localStorageUtil.logout();
        reject(error);
      }
    })
  }

  getAllByAccount(idAccount: number) : Promise<Array<User>> {
    return new Promise((resolve, reject) => {
      axios2.get(`users?account=${idAccount}`)
      .then(
        (response: any) => {
          const result = new Array<User>();
          if (response.data && response.data.data) {
            response.data.data.forEach((el: any) => {
              result.push(new User(parseInt(el.id), el));
            });
          }
          resolve(result);
        }
      ),
      (error: any) => {
        reject(error);
      }
    })
  }

  logout(nextUrl: string) {
    localStorageUtil.logout();
    if (nextUrl) {
      router.push({ name: "Login", query: { nextUrl: nextUrl } });
    } else {
      router.push({ name: "Login" });
    }
  }

  getUrl() : String {
    return "users";
  }
}