import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  iconColor: 'white',
  customClass: {
    container: "safety-toast",
    popup: 'colored-toast'
  },
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer)
    toast.addEventListener("mouseleave", Swal.resumeTimer)
  }
});

const Confirm = Swal.mixin({
  icon: "question",
  showConfirmButton: true,
  showCancelButton: true,
  cancelButtonText: "Não",
  confirmButtonText: "Sim",
  // customClass: {
  //     confirmButton: "vs-component vs-button vs-button-danger vs-button-filled mx-2",
  //     cancelButton: "vs-component vs-button vs-button-primary vs-button-filled mx-2"
  // },
  // buttonsStyling: false,
  allowOutsideClick: false,
  focusCancel: true
});

const Loading = Swal.mixin({
  title: "Aguarde...",
  allowEscapeKey: false,
  allowOutsideClick: false,
  didOpen: () => {
    Swal.showLoading();
  }
});

export { Toast, Confirm, Loading }