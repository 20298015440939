import { Model } from "@/code/models/Model";
import axios2 from '@/axios2';

export class BaseService<T extends Model> {
  constructor(private entityType: new(id: number, attributes: any) => T) {}

  get(id: number, showDetail: true) : Promise<T> {
    const that = this;
    return new Promise((resolve, reject) => {
      if (!id || id <= 0) {
        reject('O id deve ser maior que zero');
      } else {
        axios2.get(`${that.getUrl()}/${id}?detail=${ showDetail ? '1' : '2' }`)
        .then(
          (response: any) => {
            if (response.data && response.data.data) {
              resolve(new that.entityType(parseInt(response.data.data.id), response.data.data));
            } else {
              reject();
            }
          }
        ),
        (error: any) => {
          reject(error);
        }
      }
    });
  }

  getAll(): Promise<Array<T>> {
    const that = this;
    return new Promise((resolve, reject) => {
      axios2.get(`${that.getUrl()}?detail=1`)
      .then(
        (response: any) => {
          const result = new Array<T>();
          if (response.data && response.data.data) {
            response.data.data.forEach((el: any) => {
              result.push(new that.entityType(parseInt(el.id), el));
            });
          }
          resolve(result);
        }
      ),
      (error: any) => {
        reject(error);
      }
    })
  };

  save(entity: T): Promise<T> {
    const that = this;
    return new Promise((resolve, reject) => {
      axios2.post(`${that.getUrl()}`, entity)
      .then(
        (response: any) => {
          if (response && response.status >= 200 && response.status < 300) {
            const user = new that.entityType(response.data.data.id, response.data.data);
            resolve(user);
          } else {
            reject();
          }
        }
      )
    })
  };

  update(id: number, entity: T): Promise<T> {
    const that = this;
    return new Promise((resolve, reject) => {
      axios2.put(`${that.getUrl()}/${id}`, entity)
      .then(
        (response: any) => {
          if (response && response.status >= 200 && response.status < 300) {
            const user = new that.entityType(response.data.data.id, response.data.data);
            resolve(user);
          } else {
            reject();
          }
        }
      )
    })
  };

  delete(id: number) :Promise<Boolean> {
    const that = this;
    return new Promise((resolve, reject) => {
      axios2.delete(`${that.getUrl()}/${id}`)
      .then(
        (response: any) => {
          if (response && response.status >= 200 && response.status < 300) {
            resolve(true);
          } else {
            reject();
          }
        }
      )
    })
  }

  getUrl() : String {
    return "";
  }
}