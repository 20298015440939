<template>
  <v-container
    id="users"
    fluid
    tag="section"
  >
    <v-spacer />
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="usersList"
          :items-per-page="10"
          :search="filterText"
          class="elevation-1"
          item-key="id"
          :loading="loadingUsers"
          loading-text="Carregando usuários..."
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>Usuários</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                class="mt-8"
                label="Filtrar"
                v-model="filterText"
                solo
                append-icon="mdi-magnify"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="newUser"
              >
                Novo usuário
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.created="{ item }">
            {{ getFormattedDate(item.created) }}
          </template>
          <template v-slot:item.role="{ item }">
            {{ getRoleName(item.id_role) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="primary"
              fab
              x-small
              @click="editUser(item)"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
            <!-- <v-btn class="ml-3"
              color="primary"
              fab
              x-small
              @click="changePassword(item)"
            >
              <v-icon>
                mdi-lock-outline
              </v-icon>
            </v-btn> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Formulário de cadastro -->
    <v-dialog
      v-model="dialog"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.name"
                  label="Nome"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.email"
                  label="Nome de usuário"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  label="Senha"
                  counter
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>

              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  color="secondary"
                  item-color="secondary"
                  label="Selecione a conta"
                  :items="accounts"
                  v-model="editedItem.id_accounts"
                  item-text="account"
                  item-value="id"
                  :disabled="!hasManageGroupPermission"
                >
                </v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-select
                  color="secondary"
                  item-color="secondary"
                  label="Papel"
                  :items="rolesList"
                  v-model="editedItem.id_role"
                  item-text="name"
                  item-value="id"
                >
              </v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.seller_code"
                  label="Código do vendedor"
                />
              </v-col>
            </v-row>
            <v-row>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.detail.cpf"
                  label="CPF"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.detail.phone"
                  label="Telefone"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.detail.address"
                  label="Endereço"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.detail.city"
                  label="Cidade"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-select
                  color="secondary"
                  item-color="secondary"
                  label="Estado"
                  :items="statesList"
                  v-model="selectedState"
                  item-text="name"
                  return-object
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveUser"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- update password -->
    <v-dialog
      v-model="changePassOpen"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-text-field
                v-model="newPassword"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Senha"
                counter
                @click:append="show1 = !show1"
              ></v-text-field>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="cancelChangePass"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="updatePassword"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

import { mapState } from 'vuex';
import axios from 'axios';
import { baseApiUrl } from '@/global';
import moment from 'moment';
import { states, roles } from '@/code/util/constants';
import { UserService } from '@/code/services/user/userService';
import { User } from '@/code/models/User';
import { AuthUtil } from '@/code/util/authUtil';
import { EUserRole } from '@/code/enums/EUserRole';
import { EventBus } from '@/code/util/eventBus';
import localStorageUtil from '@/code/util/localStorageUtil';
import { Loading } from '@/code/util/swalUtil';
import Swal from 'sweetalert2';
import { AccountService } from '@/code/services/user/accountService';

  axios.defaults.headers.common['Content-Type'] = 'application/json';

  export default {
    data () {
      return {
        dialog: false,
        confirmDelete: false,
        successDelete: false,
        loadingUsers: false,
        usersList: [],
        editedItem: new User(0, {}),
        accounts: [],
        selectedState: {},
        filterText: "",
        show1: false,
        chancePassOpen: false,
        newPassword: '',
        hasManageGroupPermission: Boolean,
        headers: [
          {
            text: 'Id',
            value: 'id',
            filterable: false
          },
          {
            text: 'Nome',
            value: 'name',
          },
          {
            text: 'Email',
            value: 'email',
          },
          {
            text: 'Conta',
            value: 'account',
          },
          {
            text: 'Papel',
            value: 'role',
            filterable: false
          },
          {
            text: 'Criação',
            value: 'created',
          },
          { text: 'Ações', value: 'actions', sortable: false }
        ],
        statesList: states,
        rolesList: []
      }
    },
    // props: {
    //   editedItem: User
    // },
    computed: {
      formTitle: function() {
        return this.editedItem != null && this.editedItem.id > 0 ? 'Editando Usuário' : 'Novo Usuário'
      },
    },
    watch: {
      dialog (val) {
        val || this.close();
      }
    },
    mounted () {
      this.updatePermissions();
      EventBus.$on("currentUserLoaded", this.updatePermissions);
      this.loadUsers();
    },
    methods: {
      getRoleName: function(id_role) {
        var roles = this.rolesList.filter((role) => role.id === id_role);
        if (roles && roles.length > 0) {
          return roles[0].name;
        }
        return "";
      },
      createNewUser: function(user) {
        var u = {
          id: user.id ?? 0,
          id_accounts: user.id_accounts,
          id_role: user.id_role,
          login: user.login,
          email: user.email,
          name: user.name,
          seller_code: user.seller_code,
          created: user.created,
          modified: user.modified,
          account: '',
          license: ''
        }

        if (user.account_detail) {
          u.account = user.account_detail.account;
          u.license = user.account_detail.license;
        }

        return u;
      },
      loadUsers: async function() {
        this.loadingUsers = true;
        var service = new UserService();
        this.usersList = await service.getAll();
        this.loadingUsers = false;
      },
      loadAccounts: async function() {
        if (this.hasManageGroupPermission) {
          if (!this.accounts || this.accounts.length === 0) {
            const that = this;
            this.accounts = [];
            const accountService = new AccountService();
            this.accounts = await accountService.getAllAccounts(false); //axios.get(`${baseApiUrl}accounts?all=1&detail=0`);
            // if (accounts) {
            //   accounts.data.data.forEach((account) => {
            //     if (account.active === 1) {
            //       const ac = { id: account.id, account: account.account };
            //       this.accounts.push(ac);
            //       if (ac.id === that.editedItem.id_accounts) {
                    
            //       }
            //     }
            //   });
            // }
          }
        } else {
          this.accounts = [];
          const account = localStorageUtil.getCurrentUserAccount();
          this.accounts.push({ id: account.id, account: account.account });
          this.editedItem.id_accounts = account.id;
        }
      },
      editUser: async function(item) {
        Loading.fire("Carregando dados do usuário");
        const userService = new UserService();
        this.editedItem = await userService.get(item.id);
        const st = this.statesList.filter((s) => { return s.id === this.editedItem.detail.state; });
        if (st && st.length > 0) {
          this.selectedState = st[0];
        }
        Swal.close();
        //this.editedItem = new User(item.id, item);
        this.loadAccounts();
        this.dialog = true;
      },
      newUser: function() {
        this.editedItem = new User(0, {});
        this.loadAccounts();
        this.dialog = true;
      },
      getFormattedDate: function(date) {
        return date ? moment.utc(date).format('DD/MM/YYYY') : '';
      },
      close () {
        this.dialog = false;
        this.editedItem = new User(0, {});
      },
      confirmDeleteUser: function(user) {
        this.editedItem = user;
        this.confirmDelete = true;
      },
      changePassword: function(user) {
        console.log('teste');
        this.editedItem = user;
        this.changePassOpen = true;
      },
      cancelChangePass: function() {
        this.editedItem = new User(0, {});
        this.chancePassOpen = false;
      },
      updatePassword: function() {
        //do something
      },
      saveUser: async function() {
        var toSave = {
          name: this.editedItem.name,
          email: this.editedItem.email,
          id_accounts: this.editedItem.id_accounts,
          id_role: this.editedItem.id_role,
          seller_code: this.editedItem.seller_code,
          //login: this.editedItem.login,
          detail: this.editedItem.detail
        }
        if (this.selectedState && this.selectedState.id) {
          toSave.detail.state = this.selectedState.id;
          toSave.detail.country = this.selectedState.country.id;
        }
        if (this.editedItem) {
          let retorno = null;
          const userService = new UserService();
          if (this.editedItem.id > 0) {
            if (this.editedItem.password && this.editedItem.password.length > 0) {
              toSave.password = this.editedItem.password;
            }
            // response = await axios.put(`${baseApiUrl}users/${this.editedItem.id}`, toSave);
            retorno = await userService.update(this.editedItem.id, toSave);
          } else {
            toSave.password = this.editedItem.password;
            // response = await axios.post(`${baseApiUrl}users`, toSave);
            retorno = await userService.save(toSave);
          }
          if (retorno) {
            var that = this;
            var itemsById = this.usersList.filter((user) => user.id === that.editedItem.id);
            if (itemsById && itemsById.length > 0) {
              that.usersList.splice(that.usersList.indexOf(itemsById[0]), 1);
            }
            that.usersList.push(retorno);
            this.dialog = false;
          }
        }
      },
      updatePermissions: function() {
        const authUtil = new AuthUtil();
        const rule = authUtil.getUserRole();
        this.hasManageGroupPermission = rule === EUserRole.Admin || rule === EUserRole.ManageGroup;

        this.rolesList = [];
        roles.forEach(r => {
          if (r.role_enum === EUserRole.Admin && rule === EUserRole.Admin) {
            this.rolesList.push(r);
          }

          if (r.role_enum === EUserRole.ManageGroup && (rule === EUserRole.Admin || rule === EUserRole.ManageGroup)) {
            this.rolesList.push(r);
          }

          if (r.role_enum === EUserRole.ManageAccount && (rule === EUserRole.Admin || rule === EUserRole.ManageGroup || rule === EUserRole.ManageAccount)) {
            this.rolesList.push(r);
          }

          if (r.role_enum === EUserRole.Seller) {
            this.rolesList.push(r);
          }
        });
      },
    },
  }
</script>
