import { Model } from "../Model";

export class AccountFeature extends Model {

  constructor(id: number, attributes: any) {
    super(id);
    this.name = attributes.name;
  }

  name: string;

}