export class UserDetails {

  constructor(details: any) {
    this.address = details.address;
    this.city = details.city;
    this.country = details.country;
    this.state = details.state;
    this.cpf = details.cpf;
    this.phone = details.phone;
    this.zipcode = details.zipcode;
  }

  address: string;
  city: string;
  country: string;
  state: string;
  cpf: string;
  phone: string;
  zipcode: string;

}