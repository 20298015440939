import { Account } from "@/code/models/User/Account";
import { BaseService } from "@/code/services/base/baseService";
import axios2 from '@/axios2';
import { AccountFeature } from "@/code/models/User/AccountFeature";

export class AccountService extends BaseService<Account> {

  constructor() {
    super(Account);
  }

  getAllAccounts(showDetail: Boolean): Promise<Array<Account>> {
    const that = this;
    return new Promise((resolve, reject) => {
      axios2.get(`accounts?all=1&detail=${ showDetail ? '1' : '0' }&type=account`)
      .then(
        (response: any) => {
          const result = new Array<Account>();
          if (response.data && response.data.data) {
            response.data.data.forEach((el: any) => {
              result.push(new Account(parseInt(el.id), el));
            });
          }
          resolve(result);
        }
      ),
      (error: any) => {
        reject(error);
      }
    })
  }

  getGroups(showDetail: Boolean = true): Promise<Array<Account>> {
    const that = this;
    return new Promise((resolve, reject) => {
      axios2.get(`accounts?all=1&detail=${ showDetail ? '1' : '0' }&type=group`)
      .then(
        (response: any) => {
          const result = new Array<Account>();
          if (response.data && response.data.data) {
            response.data.data.forEach((el: any) => {
              result.push(new Account(parseInt(el.id), el));
            });
          }
          resolve(result);
        }
      ),
      (error: any) => {
        reject(error);
      }
    })
  };

  uploadLayout(layoutImage: Blob): Promise<Boolean> {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  };

  getFeatures(): Promise<Array<AccountFeature>> {
    return new Promise((resolve, reject) => {
      axios2.get(`accounts/features/list`)
      .then(
        (response: any) => {
          const result = new Array<AccountFeature>();
          if (response.data && response.data.data) {
            response.data.data.forEach((el: any) => {
              result.push(new AccountFeature(parseInt(el.id), el));
            });
          }
          resolve(result);
        }
      ),
      (error: any) => {
        reject(error);
      }
    })
  }

  getUrl() : String {
    return 'accounts';
  }
}