import { EUserRole } from "@/code/enums/EUserRole";
import { permissionsRules } from "./constants";
import localStorageUtil from "./localStorageUtil"

export class AuthUtil {

  constructor() {}

  getUserRole() {
    let userData = localStorageUtil.getCurrentUser();
    return userData ? userData.role_enum : EUserRole.Seller;
  }

}